import React, { FC, useContext, useState } from 'react';
import { Button, Message } from 'semantic-ui-react';

import extractDateString from '@/lib/extractDateString';
import { DateTime } from 'luxon';
import UserContext from '@/components/UserContext';
import DbContext from '@/components/DbContext';
import { getBackendConfig } from '@/lib/lambda';
import { callPaginatedPostApi } from '@/lib/paginated';
import { JoinDa, Party, Site } from '../../API';

interface GenerateLogCsvButtonsProps {
  startDate: Date | null;
  endDate: Date | null;
  countries: string[];
  votingStates: string[];
  congressionalDistricts: string[];
  leoJurisdictions: string[];
  joinDa: JoinDa | null;
  party: Party | null;
  site: Site | null;
  recordType: string;
}

const GenerateLogCsvButtons: FC<GenerateLogCsvButtonsProps> = ({
  startDate,
  endDate,
  countries,
  votingStates,
  congressionalDistricts,
  leoJurisdictions,
  joinDa,
  party,
  site,
  recordType,
}) => {
  const userContext = useContext(UserContext);
  const dbContext = useContext(DbContext);

  const [isSending, setIsSending] = useState(false);
  const [link, setLink] = useState(null);
  const [downloadFilename, setDownloadFilename] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const initStartQuery = async () => {
    if (isSending) return;
    setIsSending(true);
    setIsLoading(true);
    setLink(null);
    setErrorMsg(null);
    try {
      const data = {
        startDate: extractDateString(startDate),
        endDate: extractDateString(endDate),
        votingStates: votingStates.length > 0 ? votingStates : undefined,
        congressionalDistricts:
          congressionalDistricts.length > 0
            ? congressionalDistricts
            : undefined,
        leoJurisdictions:
          leoJurisdictions.length > 0 ? leoJurisdictions : undefined,
        countries: countries.length > 0 ? countries : undefined,
        joinDa: joinDa || undefined,
        party: party || undefined,
        site: site || undefined,
        recordType,
      };
      const config = getBackendConfig(
        userContext?.signInUserSession?.idToken.jwtToken,
      );
      config.headers.Accept = 'application/json';
      let result = null as any;
      try {
        result = (await callPaginatedPostApi(
          dbContext.db,
          `log-csv`,
          data,
          config,
        )) as any;
      } catch {
        alert(
          "API Error. Maybe your login has expired? Try refreshing the page.  If that doesn't work, try splitting your download into smaller date ranges.  Email hpiwowar@gmail.com to report this error or for additional help.",
        );
        console.log('API error');
      }
      if (result?.csvResults === undefined || result?.count === undefined) {
        alert(
          "API Error. Maybe your login has expired? Try refreshing the page.  If that doesn't work, try splitting your download into smaller date ranges.  Email hpiwowar@gmail.com to report this error or for additional help.",
        );
        throw new Error('bad API response');
      }
      const csvContents = result?.csvResults;
      const internalHrefToCsv = URL.createObjectURL(
        new Blob([csvContents], {
          type: 'text/csv;charset=utf-8;',
        }),
      );
      setIsSending(false);
      const filenameTimestamp = DateTime.now().toFormat('yyyy-LL-dd-hh-mm-ss');
      setDownloadFilename(`${filenameTimestamp}-VFA-Log.csv`);
      setIsLoading(false);
      setLink(internalHrefToCsv); // this makes the Download button appear
    } catch (error) {
      setIsSending(false);
      setIsLoading(false);
      console.log('query failed ->', error);
      if (error.errors?.length > 0) {
        setErrorMsg(error.errors[0].message);
      }
    }
  };
  return (
    <>
      <Button
        primary
        content="Generate CSV"
        disabled={isLoading}
        loading={isLoading}
        onClick={() => initStartQuery()}
      />
      {link && (
        <Button
          primary
          icon="download"
          labelPosition="right"
          content="Download"
          download={downloadFilename}
          as="a"
          href={link}
          onClick={() => setLink(null)}
        />
      )}
      {errorMsg && <Message color="red">{errorMsg}</Message>}
    </>
  );
};

export default GenerateLogCsvButtons;
