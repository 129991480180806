import React, { FC, useContext, useState } from 'react';
import { Grid, Segment } from 'semantic-ui-react';

import UserContext from '@/components/UserContext';
import CsvDataExport from '@/components/CsvDataExport';
import CsvLogExport from '@/components/CsvLogExport';

import DbContext from '@/components/DbContext';
import { getBackendConfig } from '@/lib/lambda';
import { callPaginatedPostApi, callGetApi } from '@/lib/paginated';

const DataTab: FC = () => {
  React.useEffect(() => {
    if (typeof document !== 'undefined') {
      document.title = 'VFA Dashboard (Export)';
    }

    preFetchData();
  }, []);

  const userContext = useContext(UserContext);
  const groups: string[] =
    userContext?.signInUserSession?.accessToken?.payload?.['cognito:groups'] ??
    [];

  const dbContext = useContext(DbContext);
  const [lastRefreshDate, setLastRefreshDate] = useState(null);
  const [leoOptionsData, setLeoOptionsData] = useState(null);

  const preFetchData = async () => {
    setLeoOptionsData(null);

    // Get LEO Jurisdictions from db
    try {
      const data = {};
      const config = getBackendConfig(
        userContext?.signInUserSession?.idToken.jwtToken,
      );
      let result = null as any;

      try {
        result = (await callPaginatedPostApi(
          dbContext.db,
          `get-leo-filter`,
          data,
          config,
        )) as any;
      } catch {
        alert(
          "API Error. Maybe your login has expired? Try refreshing the page.  If that doesn't work, try splitting your download into smaller date ranges.  Email hpiwowar@gmail.com to report this error or for additional help.",
        );
        console.log('API error');
      }

      setLeoOptionsData(
        result.results.map((row) => ({
          state: row.state,
          leoJurisdiction: row.leo_jurisdiction,
        })),
      );
    } catch (error) {
      console.log('query failed ->', error);
    }

    // Get last refresh time from db
    try {
      const data = {};
      const config = getBackendConfig(
        userContext?.signInUserSession?.idToken.jwtToken,
      );
      let result = null as any;

      try {
        result = (await callGetApi(
          dbContext.db,
          `data-freshness`,
          data,
          config,
        )) as any;
      } catch {
        alert(
          "API Error. Maybe your login has expired? Try refreshing the page.  If that doesn't work, try splitting your download into smaller date ranges.  Email hpiwowar@gmail.com to report this error or for additional help.",
        );
        console.log('API error');
      }

      setLastRefreshDate(result.last_refresh_time);
    } catch (error) {
      console.log('query failed ->', error);
    }
  };

  return (
    <Grid stackable columns={3}>
      {groups.includes('Admins') && (
        <Grid.Column>
          <Segment>
            <CsvDataExport leoOptionsData={leoOptionsData} />
          </Segment>
          <Segment>
            <div>
              Data last refreshed:{' '}
              {`${new Date(lastRefreshDate).toUTCString()}`}
            </div>
          </Segment>
        </Grid.Column>
      )}
      <Grid.Column>
        <Segment>
          <CsvLogExport leoOptionsData={leoOptionsData} />
        </Segment>
        <Segment>
          <div>
            Data last refreshed: {`${new Date(lastRefreshDate).toUTCString()}`}
          </div>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default DataTab;
