import React, { FC, useState, useEffect } from 'react';
import {
  Button,
  Dropdown,
  Form,
  Header,
  Icon,
  Message,
} from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { isEqual } from 'lodash';

import CountrySelect from '@/components/CountrySelect';
import VotingStateSelect from '@/components/VotingStateSelect';
import { JoinDa, Party, Site } from '@/API';
import GenerateDataCsvButtons from './GenerateDataCsvButtons';
import GenerateDataCountButtons from './GenerateDataCountButtons';

const year = new Date().getUTCFullYear();
const startDateUtc = new Date(`${year}-01-01`);
startDateUtc.setTime(
  startDateUtc.getTime() + startDateUtc.getTimezoneOffset() * 60 * 1000,
);

const INITIAL_FORM_STATE = {
  startDate: startDateUtc,
  endDate: new Date(),
  countries: [],
  votingStates: [],
  congressionalDistricts: [],
  leoJurisdictions: [],
  joinDa: null,
  party: null,
  site: null,
  recordType: 'COMPLETED',
};

interface leoOptionsDataObject {
  state: string;
  leoJurisdiction: string;
}

interface CsvDataExportProps {
  leoOptionsData: leoOptionsDataObject[];
}

const CsvDataExport: FC<CsvDataExportProps> = ({ leoOptionsData }) => {
  const calculateLeoOptions = (statesSelected: string[]) => {
    const validOptions = leoOptionsData
      ? leoOptionsData.filter(
          (option) =>
            statesSelected.length === 0 ||
            statesSelected.includes(option.state),
        )
      : [];
    return validOptions.map((option) => ({
      key: `${option.state}_${option.leoJurisdiction}`,
      value: option.leoJurisdiction,
      text: `${option.state}_${option.leoJurisdiction}`,
    }));
  };

  const [errorMsg] = useState<string | null>(null);

  const [startDate, setStartDate] = useState(INITIAL_FORM_STATE.startDate);
  const [endDate, setEndDate] = useState(INITIAL_FORM_STATE.endDate);
  const [countries, setCountries] = useState<string[]>(
    INITIAL_FORM_STATE.countries,
  );
  const [votingStates, setVotingStates] = useState<string[]>(
    INITIAL_FORM_STATE.votingStates,
  );
  const [leoJurisdictions, setLeoJurisdictions] = useState<string[]>(
    INITIAL_FORM_STATE.leoJurisdictions,
  );
  const [congressionalDistricts, setCongressionalDistricts] = useState<
    string[]
  >(INITIAL_FORM_STATE.congressionalDistricts);
  const [joinDa, setJoinDa] = useState<JoinDa | null>(
    INITIAL_FORM_STATE.joinDa,
  );
  const [party, setParty] = useState<Party | null>(INITIAL_FORM_STATE.party);
  const [site, setSite] = useState<Site | null>(INITIAL_FORM_STATE.site);
  const [recordType, setRecordType] = useState(INITIAL_FORM_STATE.recordType);

  const [leoOptions, setLeoOptions] = useState(calculateLeoOptions([]));

  const resetForm = () => {
    setStartDate(INITIAL_FORM_STATE.startDate);
    setEndDate(INITIAL_FORM_STATE.endDate);
    setCountries(INITIAL_FORM_STATE.countries);
    setVotingStates(INITIAL_FORM_STATE.votingStates);
    setLeoJurisdictions(INITIAL_FORM_STATE.leoJurisdictions);
    setCongressionalDistricts(INITIAL_FORM_STATE.congressionalDistricts);
    setJoinDa(INITIAL_FORM_STATE.joinDa);
    setParty(INITIAL_FORM_STATE.party);
    setSite(INITIAL_FORM_STATE.site);
    setRecordType(INITIAL_FORM_STATE.recordType);
    setLeoOptions(calculateLeoOptions([]));
  };

  const formHasChanges = !isEqual(INITIAL_FORM_STATE, {
    startDate,
    endDate,
    countries,
    votingStates,
    congressionalDistricts,
    leoJurisdictions,
    joinDa,
    party,
    site,
    recordType,
  });

  const handleVotingStateChange = (event, data) => {
    setVotingStates(data.value);
    // Also update LEO dropdown to only include jurisdictions in those states
    setLeoOptions(calculateLeoOptions(data.value));
    // And update the selected jurisdictions to only be in currently selected states
    setLeoJurisdictions(
      leoJurisdictions.filter((leoJurisdiction) =>
        leoOptions.map((option) => option.value).includes(leoJurisdiction),
      ),
    );
  };

  useEffect(() => {
    setLeoOptions(calculateLeoOptions([]));
  }, [leoOptionsData]);

  return (
    <Form>
      <Header as="h2">VFA Full Data Export</Header>
      <Message warning icon visible>
        <Icon name="warning sign" />
        <Message.Content>
          This will return live voter data including confidential personally
          identifiable information (PII). Full data available for the last
          twelve months only. Use with caution!
        </Message.Content>
      </Message>
      <Form.Field>
        <label>Start date</label>
        <SemanticDatepicker
          value={startDate}
          onChange={(_evt, data) => setStartDate(data.value as Date)}
        />
      </Form.Field>
      <Form.Field>
        <label>End date</label>
        <SemanticDatepicker
          value={endDate}
          onChange={(_evt, data) => setEndDate(data.value as Date)}
        />
      </Form.Field>
      <Form.Group grouped>
        <label>Record type</label>
        <Form.Radio
          label="Completed"
          value="COMPLETED"
          checked={recordType === 'COMPLETED'}
          onChange={(_evt, data) => setRecordType(data.value as string)}
        />
        <Form.Radio
          label="Incomplete"
          value="INCOMPLETE"
          checked={recordType === 'INCOMPLETE'}
          onChange={(_evt, data) => setRecordType(data.value as string)}
        />
        <Form.Radio
          label="All"
          value="ALL"
          checked={recordType === 'ALL'}
          onChange={(_evt, data) => setRecordType(data.value as string)}
        />
      </Form.Group>
      <Form.Field>
        <label>Country</label>
        <CountrySelect
          multiple
          search
          value={countries}
          selection
          onChange={(_evt, data) => setCountries(data.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>Voting State</label>
        <VotingStateSelect
          search
          multiple
          value={votingStates}
          selection
          onChange={handleVotingStateChange}
        />
      </Form.Field>
      <Form.Field>
        <label>LEO Jurisdiction</label>
        <Dropdown
          search
          multiple
          value={leoJurisdictions}
          selection
          options={leoOptions}
          onChange={(_evt, data) => setLeoJurisdictions(data.value as string[])}
        />
      </Form.Field>
      {/* <Form.Field> */}
      {/*  <label>Congressional District</label> */}
      {/*  <CongressionalDistrictSelect */}
      {/*    search */}
      {/*    multiple */}
      {/*    value={congressionalDistricts} */}
      {/*    selection */}
      {/*    onChange={(_evt, data) => setCongressionalDistricts(data.value)} */}
      {/*  /> */}
      {/* </Form.Field> */}
      <Form.Field>
        <label>Join DA</label>
        <Dropdown
          clearable
          value={joinDa}
          options={[
            { key: 'ALL', value: null, text: '' },
            { key: JoinDa.TRUE, value: JoinDa.TRUE, text: 'True' },
            { key: JoinDa.FALSE, value: JoinDa.FALSE, text: 'False' },
            {
              key: JoinDa.ALREADY_A_MEMBER,
              value: JoinDa.ALREADY_A_MEMBER,
              text: 'Already a member',
            },
          ]}
          selection
          onChange={(_evt, data) => setJoinDa(data.value as JoinDa)}
        />
      </Form.Field>
      <Form.Field>
        <label>Party</label>
        <Dropdown
          clearable
          value={party}
          options={[
            { key: 'ALL', value: null, text: '' },
            {
              key: Party.DEMOCRATIC,
              value: Party.DEMOCRATIC,
              text: 'Democratic',
            },
            {
              key: Party.REPUBLICAN,
              value: Party.REPUBLICAN,
              text: 'Republican',
            },
            {
              key: Party.OTHER,
              value: Party.OTHER,
              text: 'Other Party',
            },
            {
              key: Party.PREFER_NOT_TO_ANSWER,
              value: Party.PREFER_NOT_TO_ANSWER,
              text: 'Prefer not to answer',
            },
            {
              key: Party.NO_PARTY_PREFERENCE,
              value: Party.NO_PARTY_PREFERENCE,
              text: 'No party preference',
            },
          ]}
          selection
          onChange={(_evt, data) => setParty(data.value as Party)}
        />
      </Form.Field>
      <Form.Field>
        <label>Website version</label>
        <Dropdown
          clearable
          value={site}
          options={[
            { key: 'NO ANSWER', value: null, text: '' },
            {
              key: Site.STANDARD,
              value: Site.STANDARD,
              text: 'VFA Standard',
            },
            {
              key: Site.SPANISH,
              value: Site.SPANISH,
              text: 'VFA Spanish',
            },
            {
              key: Site.STUDENT,
              value: Site.STUDENT,
              text: 'Students',
            },
            {
              key: Site.BETA,
              value: Site.BETA,
              text: 'Beta',
            },
          ]}
          selection
          onChange={(_evt, data) => setSite(data.value as Site)}
        />
      </Form.Field>
      <Form.Field>
        <GenerateDataCountButtons
          startDate={startDate}
          endDate={endDate}
          countries={countries}
          congressionalDistricts={congressionalDistricts}
          leoJurisdictions={leoJurisdictions}
          joinDa={joinDa}
          party={party}
          site={site}
          votingStates={votingStates}
          recordType={recordType}
        />
      </Form.Field>
      <Form.Field>
        <GenerateDataCsvButtons
          startDate={startDate}
          endDate={endDate}
          countries={countries}
          congressionalDistricts={congressionalDistricts}
          leoJurisdictions={leoJurisdictions}
          joinDa={joinDa}
          party={party}
          site={site}
          votingStates={votingStates}
          recordType={recordType}
        />
      </Form.Field>
      {formHasChanges && (
        <Form.Field>
          <Button onClick={resetForm}>Reset form</Button>
        </Form.Field>
      )}
      {errorMsg && <Message color="red">{errorMsg}</Message>}
    </Form>
  );
};

export default CsvDataExport;
