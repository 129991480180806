import React, { FC, useContext, useState } from 'react';

import { Button, Message, Statistic } from 'semantic-ui-react';
import styled from '@emotion/styled';

import UserContext from '@/components/UserContext';
import DbContext from '@/components/DbContext';

import extractDateString from '@/lib/extractDateString';
import { getBackendConfig } from '@/lib/lambda';
import { callPaginatedPostApi } from '@/lib/paginated';
import { JoinDa, Party, Site } from '../../API';

interface GenerateLogCountButtonsProps {
  startDate: Date | null;
  endDate: Date | null;
  countries: string[];
  votingStates: string[];
  congressionalDistricts: string[];
  leoJurisdictions: string[];
  joinDa: JoinDa | null;
  party: Party | null;
  site: Site | null;
  recordType: string;
}

const StatsContainer = styled.span`
  margin-left: 10px;
`;

const GenerateLogCountButtons: FC<GenerateLogCountButtonsProps> = ({
  startDate,
  endDate,
  countries,
  votingStates,
  congressionalDistricts,
  leoJurisdictions,
  joinDa,
  party,
  site,
  recordType,
}) => {
  const userContext = useContext(UserContext);
  const dbContext = useContext(DbContext);
  const [isSending, setIsSending] = useState(false);
  const [count, setCount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const initStartQuery = async () => {
    if (isSending) return;
    setIsSending(true);
    setIsLoading(true);
    setCount(null);
    setErrorMsg(null);

    try {
      const data = {
        startDate: extractDateString(startDate),
        endDate: extractDateString(endDate),
        votingStates: votingStates.length > 0 ? votingStates : undefined,
        congressionalDistricts:
          congressionalDistricts.length > 0
            ? congressionalDistricts
            : undefined,
        leoJurisdictions:
          leoJurisdictions.length > 0 ? leoJurisdictions : undefined,
        countries: countries.length > 0 ? countries : undefined,
        joinDa: joinDa || undefined,
        party: party || undefined,
        site: site || undefined,
        recordType,
      };
      const config = getBackendConfig(
        userContext?.signInUserSession?.idToken.jwtToken,
      );
      let result = null as any;

      try {
        result = (await callPaginatedPostApi(
          dbContext.db,
          `data-count`,
          data,
          config,
        )) as any;
      } catch {
        alert(
          "API Error. Maybe your login has expired? Try refreshing the page.  If that doesn't work, try splitting your download into smaller date ranges.  Email hpiwowar@gmail.com to report this error or for additional help.",
        );
        console.log('API error');
      }
      if (result?.count === undefined) {
        alert(
          "API Error. Maybe your login has expired? Try refreshing the page.  If that doesn't work, try splitting your download into smaller date ranges.  Email hpiwowar@gmail.com to report this error or for additional help.",
        );
        throw new Error('bad API response');
      }
      setIsSending(false);
      setIsLoading(false);
      setCount(parseInt(result.count, 10));
    } catch (error) {
      setIsSending(false);
      setIsLoading(false);
      console.log('query failed ->', error);
      if (error.errors?.length > 0) {
        setErrorMsg(error.errors[0].message);
      }
    }
  };

  return (
    <>
      <Button
        primary
        content="Count Rows"
        disabled={isLoading}
        loading={isLoading}
        onClick={() => initStartQuery()}
      />
      {count !== null && (
        <StatsContainer>
          <Statistic
            horizontal
            label="rows"
            size="mini"
            value={count.toLocaleString('en-US')}
          />
        </StatsContainer>
      )}
      {errorMsg && <Message color="red">{errorMsg}</Message>}
    </>
  );
};

export default GenerateLogCountButtons;
